<template>
  <div class="era-aboutus-container">
    <div class="era-aboutus-title">关于微观纪元</div>
    <div class="era-aboutus-advantage">
      <div class="adv"><img src="@/assets/images/aboutus-02.png" />
        <p>我们是梦想家<br>在新兴领域探索无限可能</p>
      </div>
      <div class="adv"><img src="@/assets/images/aboutus-03.png" />
        <p>我们是开拓者<br>走在量子计算产业前线</p>
      </div>
      <div class="adv"><img src="@/assets/images/aboutus-04.png" />
        <p>我们是设计师<br>用量子的技巧筑造新的世界</p>
      </div>
    </div>
    <div class="era-aboutus-text">
      合肥微观纪元数字科技有限公司是一家专注于量子计算的算法应用软件和行业解决方案的企业。
      公司拥有来自中科大少年班、清华大学、复旦大学、浙江大学等知名高校的博士核心团队，具备跨越量子计算、结构化学、计算生物和
      人工智能等多个领域的技术攻关能力，并已与中国科学技术大学、复旦大学、华中科技大学、国家超级计算无锡中心、中科院上海药物研究所等多家单位建立了前沿合作关系。
      公司以解决量子计算产业应用领域的卡脖子问题，填补国内相关产业的空白为使命。用量子化学模拟、量子优化组合等核心技术革新药物发现、新材料设计等领域的既有范式。
      <br><br>
      深耕量子计算，赋能千行百业。
    </div>
    <div class="era-aboutus-video">
      <video
        muted
        class="hcs-video-show"
        loop="loop"
        autoplay="autoplay"
        preload="auto"
        controls="autoplay"
      >
        <source
          src="@/assets/images/compancy-video.mp4"
          type="video/mp4"
        >
      </video>
    </div>
    <div class="era-technology">
      <div class="era-technology-title">技术团队</div>
      <img
        width="70%"
        height="100%"
        style="margin: 2vw 15%;"
        src="@/assets/images/technology-team.png"
      >
    </div>
    <div class="era-technology-title">最新事件</div>
    <table v-if="newsList.rows">
      <tr
        v-for="(item, index) in newsList.rows"
        :key="index"
      >
        <td class="news-date">
          {{item.createTime? item.createTime.split(" ")[0] : ''}}
        </td>
        <td class="news-title" @click="reserve(item.newsId)">{{ item.newsTitle }}</td>
      </tr>
    </table>
    <div
      v-else
      class="empty-data"
    >
      <span>暂无数据</span>
    </div>

  </div>
</template>
<script>
import { getNews } from "@/api/index";
export default {
  name: "AboutUs",
  components: {},
  // beforeCreate() {},
  created() {
    this.getnewsList();
  },
  // beforeDestroy() {},
  // filters: {},
  data() {
    return {
      newsList: [],
    };
  },
  methods: {
    async getnewsList() {
      this.loading = true;
      let res = await getNews(this.queryParams);
      if (res.code == 200) {
        this.newsList = res;
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
      console.log(length);
    },
    reserve(id) {
      this.$router.push({
        path: "/news/" + id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.era-aboutus-container {
  background: url("../assets/images/aboutus-01.png") no-repeat center 0px;
  background-position: center 0px;
  background-size: cover;
  width: 100%;
  .era-aboutus-title {
    color: rgb(0, 0, 0);
    font-family: 思源黑体 CN;
    font-size: 3.8vw;
    font-weight: 580;
    letter-spacing: 0px;
    margin: 5% 37%;
  }
  .era-aboutus-advantage {
    margin-top: 4vw;
    display: flex;
    .adv {
      height: 20vw;
      width: 20vw;
      margin: 0 auto;
      img {
        height: 65%;
        width: 65%;
        margin-left: 3.5vw;
      }
      p {
        font-size: 1.2vw;
        font-weight: 500;
        line-height: 2.1vw;
        text-align: center;
        font-family: 思源黑体 CN;
      }
    }
  }
  .era-aboutus-text {
    margin: 4vw 8.5vw;
    font-size: 1.3vw;
    font-weight: 500;
    line-height: 2.2vw;
    font-family: 思源黑体 CN;
  }
  .era-aboutus-video {
    video {
      height: 30%;
      width: 70%;
      margin-left: 16vw;
    }
  }
  .era-technology-title {
    font-size: 2.6vw;
    font-weight: bold;
    color: #000000;
    margin: 5vw 0 0 5vw;
  }
  table {
    margin: 2vw 5vw 10vw;
    font-size: 1.3vw;
    font-weight: 500;
    line-height: 2.5vw;
    font-family: 思源黑体 CN;
    .news-title {
      padding-left: 5vw;
      cursor: pointer;
    }
  }
}
</style>
